import React from 'react'
/**
 * Take Incense data and make it pretty
 */
class IncenseInterpreter extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            visibile:false
        }
        var comp = this
        setInterval(function() {
            comp.setState({
                time : Date.now()
            })
        },1000)
    }

  render() {
    return (
      <div>
        <div className={((this.props.data.end_time - this.state.time) > -5000) ? "color-change max" : "hidden"}>
            <div className="subtext">
                {getType(this.props.data.type) + " Incense"} 
            </div>
            <div className="subtext-xl">
                {msToTime(this.props.data.end_time - this.state.time)}
            </div>
        </div>
      </div>
    );
  }
}
function getType(type) {
  switch(type) {
      case 3:
        return "Rare Pokemon"
      case 4:
        return "Super Rare Pokemon"
      case 5:
        return "Legendary Pokemon"
      case 6:
        return "Shiny Pokemon"
      default:
        return ""
  }
}
/**
 * Converts epoch ms time to readable time
 * @param duration 
 */
function msToTime(duration) {
    if(duration < 0) {
        return "Expired"
    }
    var seconds = parseInt((duration/1000)%60)
    var minutes = parseInt((duration/(1000*60))%60)
    var hours = parseInt((duration/(1000*60*60)))
    hours = (Math.abs(hours) < 10) ? "0" + hours : hours
    minutes = (Math.abs(minutes) < 10) ? "0" + minutes : minutes
    seconds = (Math.abs(seconds) < 10) ? "0" + seconds : seconds
    if(hours === 0) {
        return minutes + ":" + seconds;
    } else {
        return hours + ":" + minutes + ":" + seconds
    }
}
export default IncenseInterpreter