import React from 'react'
import MaterialIcon from 'material-icons-react'

class DisplayModule extends React.Component {
    /**
     * @prop username Twitter Username
     * @prop feed Feed type: (Likes, Tweets, etc.) Check twitter widget dev page for more options
     */
  render() {
    return (
      <div style={getBgColor(this.props.color)} className="module-container">
        <div className="module-header">
          <MaterialIcon icon={this.props.icon} size='large' invert/><div>{this.props.title}</div>
        </div>
          {this.props.children}
      </div>
    );
  }
}
function getBgColor(color) {
  if(color !== undefined) {
    return {'backgroundColor': color}
  }
  return {'backgroundColor': 'grey'}
}
export default DisplayModule