import React, { Component } from 'react'
import axios from 'axios'
import BomttomBar from './components/BottomBar.jsx'
import DisplayModule from './components/DisplayModule.jsx'
import IncenseInterpreter from './components/IncenseInterpreter.jsx'
import PubSub from './PubSub.js'
import './App.css'
import Tony from './tony.jpg'
import 'react-twitter-widgets'
export const BASE_API_URL = process.env.NODE_ENV === 'pr'
  ? 'https://pokethon.net'
  : 'http://staging.pokethon.org';
  // : 'http://localhost:3000';
axios.defaults.baseURL = BASE_API_URL

class App extends Component {
  constructor(props) {
    super(props)
    var repInc = {'start_time':0,'end_time':0,'type':0}
    this.state = {
      background: Tony,
      donationTotal: 0,
      wheelSpins: [],
      incense: repInc,
      currentGame: "OR/AS",
      filenameRace: [],
      donationGoals: [],
      notes: [],
      currentPrizes: [],
      grandPrize: []
    }
    this.updateDonationAmount()
    this.updateFilenameRace(this.state.currentGame)
    this.updateDonationGoals()
    //this.updatePrizes()
    //this.getGrandPrize()
    //this.updateNotes()
    //FAST CYCLE / High Priority Intervals
    setInterval(() => {
      this.updateDonationAmount()
      //this.updateFilenameRace(this.getFilenameBlock())
      this.updateFilenameRace(this.state.currentGame)
    }, 1000 * 60)
    //SLOW CYCLE / Low Priority Intervals
    // setInterval(() => {
    //   this.updatePrizes()
    //   this.updateNotes()
    //   this.updateDonationGoals()
    // }, 1000 * 60 * 5)

    /**
     * Goal wheel results PubSub data from stream-overlay
     */
    PubSub.subscribe('/api/goal-wheel-actions/result', ({ obj }) => {
      var currentSpins = this.state.wheelSpins
      currentSpins.push(obj)
      this.setState({
        wheelSpins: currentSpins
      })
    })
    /**
     * Remove accomplished goal wheel actions
     */
    PubSub.subscribe('/api/goal-wheel-actions/clear', id => {
      let spins = this.state.wheelSpins
      for (let i = 0; i < spins.length; i++) {
        if (spins[i]._id === id) spins.splice(i, 1)
      }
      this.setState({
        wheelSpins: spins
      })
    })
    /**
     * Get prizes text via pubsub
     */
    PubSub.subscribe('/api/stream-hud/prizes', prizes => {
      this.setState({
        currentPrizes: prizes
      })
    })
    /**
     * Get notes via pubsub
     */
    PubSub.subscribe('/api/stream-hud/notes', notes => {
      this.setState({
        notes: notes
      })
    })
    /**
     * Incense PubSub data from public-dashboard
     * Sets state so IncenseInterpreter can do it's work
     */
    PubSub.subscribe('/api/incense/activate', ({ type, start_time, end_time }) => {
      var repInc = {'start_time':start_time,'end_time':end_time,'type':type}
      this.setState({
        incense : repInc
      })
    }) 
    /**
     * Replace Tony with specified image PubSub data from private-dashboard
     */
    PubSub.subscribe('/api/stream-hud/background', bg => {
      this.setState({
        background: (bg) ? bg : Tony
      })
    })
    /**
     * Update current game via pubsub and get corresponding filenames
     */
    PubSub.subscribe('/api/stream-hud/update-game', game => {
      this.setState({
        currentGame: game
      })
      this.updateFilenameRace(game)
    })
  }
  /**
   * Retrieve donation total
   * @returns Donation total
   */
  updateDonationAmount () {
    axios.get('api/donations/total')
      .then(({ data: { total } }) => {
        this.setState({donationTotal : total})
      }, err => {
        console.error('Failed to update donation total', err)
      })
  }
  /**
   * Retrieve all goals not accomplished and dismissed
   * @returns Goals
   */
  updateDonationGoals () {
    axios.get('api/donation-goals/')
      .then(({ data }) => {
        var goals = data.map(obj => {
          if(obj.hasBeenReached && !obj.hasBeenDismissed) {
            return obj.name + " ($" + obj.amount + ")"
          } else {
            return ""
          }
        })
        this.setState({donationGoals : goals})
      }, err => {
        console.error('Failed to update donation total', err)
      })
  }
  /**
   * Retrieve all filenames for a game
   * @param String Filter for game
   * @returns Matching filenames
   */
  updateFilenameRace(game) {
    axios.get('api/filenames/totals')
    .then(({ data }) => {
        var filteredFilenames = data.totals.map(obj => {
          if(obj.game === game) {
            return obj.name + " (" + obj.amount + ")"
          } else {
            return ""
          }
        })
        this.setState({filenameRace : filteredFilenames})
    }, err => {
      console.error('Failed to update filenames', err)
    })
  }
  /**
   * Get prize data, excluding grand prize
   * @returns Current prize block prizes
   */
  updatePrizes() {
    axios.get('api/prizes')
    .then(({ data }) => {
        var retValue = []
        data.forEach(function(obj){
          var startDate = Date.parse(obj.startDate)
          var stopDate = startDate.setHours(startDate.getHours() + 15)
          if(startDate <= Date.now() <= stopDate) {
            retValue = obj.prizes
          }
        })
        this.setState({currentPrizes : retValue})
    }, err => {
      console.error('Failed to update prizes', err)
    })
  }
  /**
   * Get grand prize
   * @returns Prizes contained in grand prize
   */
  getGrandPrize() {
    axios.get('api/prizes')
    .then(({ data }) => {
        var retValue = []
        data.forEach(function(obj){
          if(obj.name === "Grand Prize") {
            retValue = obj.prizes
          }
        })
        this.setState({grandPrize : retValue})
    }, err => {
      console.error('Failed to update grand prizes', err)
    })
  }
  /**
   * Retrieve all notes set on private dashboard
   * @returns Notes
   */
  updateNotes() {
    axios.get('api/stream-hud/notes')
      .then(({ data }) => {
        var parseData = JSON.parse(data)
        this.setState({notes : parseData.notes})
      }, err => {
        console.error('Failed to update notes', err)
      })
  }
  /**
   * Get block for filenames
   * Manual and cruddy until a "isActiveWar" flag is built for milestone 11
   * @returns Most upcoming game for filename war
   */
  getFilenameBlock() {
    //TODO
  }
  render() {
    return (
      <div className="App">
        <header className="App-header">
          <img src={this.state.background} alt="tony" className="tony" width="100%"/>
          <div className="trans">
            <BomttomBar startTime="1550160000000" donationTotal={this.state.donationTotal}/>
            <div className="module-column-1">
              <span><DisplayModule color="#00a7c4" title="Goal Wheel" icon="autorenew"><ul>{generateList(this.state.wheelSpins.map(obj => obj.name))}</ul></DisplayModule></span>
              <span><DisplayModule color="#462633" title="Donation Goals" icon="data_usage"><ul>{generateList(this.state.donationGoals)}</ul></DisplayModule></span>
              <span><DisplayModule color="#d16c07" title={`Filenames (${this.state.currentGame.substring(0, 6)})`} icon="equalizer"><ul>{generateList(this.state.filenameRace)}</ul></DisplayModule></span>
            </div>
            <div className="module-column-2">
              <span><DisplayModule color="#884a63" title="Prizes" icon="card_giftcard"><ul>{generateList(this.state.currentPrizes)}</ul></DisplayModule></span>
              <span><DisplayModule color="#f9a755" title="Notes" icon="assignment"><ul>{generateList(this.state.notes)}</ul></DisplayModule></span>
              <span><DisplayModule color="#007c91" title="Incense" icon="waves"><IncenseInterpreter data={this.state.incense}></IncenseInterpreter></DisplayModule></span>
            </div>
            <a className="twitter-timeline"
              href="https://twitter.com/poke_thon/likes"
              data-chrome="nofooter noheader noscrollbar transparent"
              data-theme="dark"
              width="30vw"
              theme="dark">
            </a>
          </div>
        </header>
      </div>
    )
  }
}
/**
 * Turn an array of strings into a bulleted list for easy displaying
 * @param String[] theList 
 */
function generateList(theList) {
  var liItems = []
  var count = 0;
  theList.forEach(function(item){
    if(item !== "") {
      var genItem = React.createElement('li',{key:count},item)
      liItems.push(genItem)
    }
    count++;
  })
  return liItems
}

export default App
