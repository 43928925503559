import React from 'react'

class BottomBar extends React.Component {
    /**
     * optional @prop startTime time in epoch ms that the marathon begins
     */
    constructor(props) {
        super(props)
        this.state = {
            time:"0"
        }
        var comp = this;
        setInterval(function() {
            var date = new Date()
            comp.setState({
                time : formatAMPM(date)
            })
        },1000)
    }
  render() {
    return (
		<div id="bottombar">
            <div id="brand">
                Pokéthon Journey
            </div>
            <div id="donation-total">
                ${this.props.donationTotal}
            </div>
            <div id="barbody">
                {(this.props.startTime < Date.now()) ? "Elapsed Time:" : "Countdown:"} {this.props.startTime !== undefined ? marathonTime(this.props.startTime) : ""}
            </div>
            <div id="feature">
                {this.state.time}
            </div>
		</div>
    )
  }
}
/**
 * Creates a marathon-timer (Time since start/Time until start)
 * @param startTime Time in epoch ms that the marathon will begin
 */
function marathonTime(startTime) {
    var nowDate = Date.now();
    var timeStamp = msToTime((nowDate - startTime))
    return timeStamp.toString()
}
/**
 * Converts epoch ms time to readable time
 * @param duration 
 */
function msToTime(duration) {
    var seconds = Math.abs(parseInt((duration/1000)%60))
    var minutes = Math.abs(parseInt((duration/(1000*60))%60))
    var hours = Math.abs(parseInt((duration/(1000*60*60))))
    hours = (hours < 10) ? "0" + hours : hours
    minutes = (minutes < 10) ? "0" + minutes : minutes
    seconds = (seconds < 10) ? "0" + seconds : seconds
    
    return hours + ":" + minutes + ":" + seconds
  }
  /**
   * Converts date into 12-Hour Format clock
   * @param date A date
   */
function formatAMPM(date) {
    var hours = date.getHours()
    var minutes = date.getMinutes()
    var seconds = date.getSeconds()
    var ampm = hours >= 12 ? 'pm' : 'am'
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    seconds = (seconds < 10) ? "0" + seconds : seconds;
    var strTime = hours + ':' + minutes + ':' + seconds + ' ' + ampm
    return strTime
  }
export default BottomBar
